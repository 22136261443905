<template lang="html">
  <div id="product-description-page">

    <section class="breadcrumb-section">
      <div class="container oversized-container">
        <span>Home</span>
        <span class="line">/</span>
        <span>Productos</span>
        <span class="line">/</span>
        <span>Chorizo Especial</span>
      </div>
    </section>

    <section class="container oversized-container product-info-section">
      <div class="row">
        <div class="col-lg-6 col-gallery">
          <swiper class="swiper swiper-s1 secondary" :options="galleryOptions">
            <swiper-slide>
              <div class="box-image" v-bind:style="{ backgroundImage: 'url(public/images/pages/products/product-3.jpg)' }">
                <img src="public/images/shared/product.png">
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="box-image" v-bind:style="{ backgroundImage: 'url(public/images/pages/products/product-3.jpg)' }">
                <img src="public/images/shared/product.png">
              </div>
            </swiper-slide>

            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>

        <div class="col-lg-6 col-info">
          <h1 class="p-name">Chorizo Especial</h1>
          <!-- <h3 class="p-price"><span class="discount">$1,900</span> <span>$1,650</span></h3> -->
          <h3 class="mt-3 p-price"><span>$210.00</span></h3>
          <h6 class="mt-1 p-label">+ $50 MXN envío en toda la Zona Metropolitana</h6>

          <h6 class="mt-4 subtitle">Descripción:</h6>
          <div class="box-descr">
            <p>
              Pierna trasera de cerdo, tenderizada y maseajada
              con salmuera. Embutido en funda de cocimiento directo,
              cocido a una temperatura mayor de 70° C
            </p>
          </div>

          <!-- <h6 class="mt-4 subtitle">Medidas:</h6>
          <div class="box-descr">
            <strong>Alto:</strong> 41mm | <strong>Ancho:</strong> 147mm | <strong>Largo:</strong> 145mm
          </div> -->

          <div class="mt-4 box-table">
            <table class="table table-sm">
              <tbody>
                <tr>
                  <th scope="row">Especie</th>
                  <td>Pierna trasera de cerdo</td>
                </tr>
                <tr>
                  <th scope="row">Presentación</th>
                  <td>Funda transparente en molde mandolina. Reja 33*51*28 cm con 4 piezas por reja</td>
                </tr>
                <tr>
                  <th scope="row">Estiba</th>
                  <td>5 Rejas de base*5 rejas de alto= 25 rejas</td>
                </tr>
                <tr>
                  <th scope="row">Contenido neto</th>
                  <td>5 Kg por pieza</td>
                </tr>
                <tr>
                  <th class="_row" colspan="2">EMPAQUE</th>
                </tr>
                <tr>
                  <th scope="row">Primario</th>
                  <td>Bolsa Termoencogible</td>
                </tr>
                <tr>
                  <th scope="row">Secundario</th>
                  <td>Rejas de plastico</td>
                </tr>
                <tr>
                  <th scope="row">Recepción</th>
                  <td>Temperatura de entre 0 y 4°c</td>
                </tr>
                <tr>
                  <th scope="row">Interpretación del código del producto</th>
                  <td>032823 donde: 03 es el mes, 28 es del día y 23 el año de elaboración formato mm/dd/aa</td>
                </tr>
                <tr>
                  <th scope="row">Condiciones de almacenaje</th>
                  <td>Mantengase entre 0 y 4°c (Refrigeración)</td>
                </tr>
                <tr>
                  <th scope="row">Vida de anaquel</th>
                  <td>30 Días</td>
                </tr>
                <tr>
                  <th class="_row" colspan="2">CARACTERÍSTICAS</th>
                </tr>
                <tr>
                  <th scope="row">Organolepticas</th>
                  <td>Olor: Característico, Sabor: Característico, Color: Característico</td>
                </tr>
                <tr>
                  <th scope="row">Microbiológicas</th>
                  <td>Mesófilos Aerobios (UFC/g) 10000 UFC/g; Coliformes fecales (NMP/g) <3 NMO/g: Salmonella por 25g Ausente/25g</td>
                </tr>
                <tr>
                  <th scope="row">Fisicoquimicas</th>
                  <td>%Humedad 76% Max; %PLG 16% Min; %Grasa 6% Max</td>
                </tr>
              </tbody>
            </table>

            <table class="mt-2 table table-sm">
              <tbody>
                <tr>
                  <th scope="row">Días restantes de caducidad para enviar a tienda</th>
                  <td>6 días</td>
                </tr>
                <tr>
                  <th scope="row">Días restantes de caducidad para exhibir en tienda</th>
                  <td>20 días</td>
                </tr>
              </tbody>
            </table>
          </div>

          <h6 class="mt-4 subtitle">Cantidad:</h6>
          <div class="col-quantity2 mt-2">
            <a class="form-control btn-q" @click="changeCantidad('-')">
              <i class="fas fa-minus"></i>
            </a>
            <b-form-input class="input-q" type="number" min="0" v-model="form.quantity" @keypress="isNumber($event)" @paste="noPaste" />
            <a class="form-control btn-q" @click="changeCantidad('+')">
              <i class="fas fa-plus"></i>
            </a>
          </div>

          <p class="mt-4 text-center text-lg-left">
            <button class="btn btn-s1 tertiary px-5 btn-add" type="button" name="button">Agregar</button>
          </p>
        </div>
      </div>
    </section>

    <section class="container oversized-container related-products-section">
      <div class="d-block pb-4">
        <h4 class="title-s1">Otros productos interesantes</h4>
        <hr class="mt-1" />
      </div>

      <swiper class="swiper" :options="productsOptions">
        <swiper-slide v-for="(p, pInx) in products" :key="'pInx-'+pInx">
          <div class="col-12 px-0 box-product-sample-s1">
            <router-link class="box-link" to="/modelos/3">
              <!-- <span class="bubble">Nuevo</span> -->
              <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+p.imageUrl+')' }">
                <img src="public/images/shared/product.png">
              </div>

              <div class="box-descr">
                <!-- <h6 class="brand">PATEK PHILIPPE</h6> -->

                <h6 class="name">{{ p.name }}</h6>
                <div class="descr">
                  <p>Aquí va una descripción corta, no la descripción larga, con poco contenido, no más de 170 caracteres.</p>
                </div>

                <h6 class="price">${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(p.price) }}</h6>

                <p class="mt-3">
                  <span class="btn-more">VER MÁS</span>
                </p>
              </div>
            </router-link>
          </div>
        </swiper-slide>

        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      material: {},
      recubrimiento: {},

      // Materieles
      materiales: [],

      // Recubrimientos
      recubrimientos: [],

      products: [
        { imageUrl: 'public/images/pages/products/product-7.jpg', price: '125', name: 'Jamon cocido' },
        { imageUrl: 'public/images/pages/products/product-8.jpg', price: '105', name: 'Jamon horneado' },
        { imageUrl: 'public/images/pages/products/product-10.jpg', price: '105', name: 'Jamon Aris' },
      ],

      // Formulario principal
      form: {
        quantity: 1,
        material: null,
        recubrimiento: null,
      },

      // == Carousel options ==
      galleryOptions: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },

      productsOptions: {
        slidesPerView: 3,
        spaceBetween: 30,

        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },

        breakpoints: {
          1100: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 2,
          },
          1: {
            slidesPerView: 1,
          },
        }
      }
      // == ==
    }
  },

  methods: {
    noPaste(evt){
      event.preventDefault();
    },

    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    changeCantidad($type){
      this.form.quantity = parseInt(this.form.quantity);
      if ($type == '-') {
        this.form.quantity = (this.form.quantity > 1) ? --this.form.quantity : 1;
      }
      else{
        this.form.quantity = (this.form.quantity < 100) ? ++this.form.quantity : 100;
      }
    },
  },
}
</script>
