<template lang="html">
  <footer id="footer">
    <section class="newsletter-section">
      <h2>Suscrítebe a nuestro Newsletter</h2>

      <b-form @submit="onSubmit">
        <b-form-group>
          <b-form-input
            v-model="form.email"
            type="email"
            required
            placeholder="Correo electrónico"
          ></b-form-input>
        </b-form-group>

        <b-button type="submit" class="btn-send">Enviar</b-button>
      </b-form>
    </section>

    <section class="footer-section">
      <div class="container oversized-container">
        <div class="row align-items-center">
          <div class="col-12 col-sm-6 col-lg col-footer">
            <h6>Dirección</h6>

            <p>
              Cerrada del río No.5<br />
              Col. El Gavillero Villa Nicolás Romero<br />
              Estados de México, C.P. 54459
            </p>
          </div>

          <div class="col-12 col-sm-6 col-lg col-footer">
            <h6>Teléfonos</h6>

            <p>
              <i class="fas fa-phone mr-1"></i> 55 2168 4040
            </p>
            <!-- <p>
              <i class="fab fa-whatsapp mr-1"></i> 33 3333 3333
            </p> -->
          </div>

          <div class="col-lg col-logo">
            <router-link to="/">
              <img src="public/images/logo-white.svg">
            </router-link>
          </div>

          <div class="col-12 col-sm-6 col-lg col-footer col-r">
            <h6>Correos Electrónicos</h6>

            <p>
              jsantos@laspolvillas.online
            </p>
            <p>
              contacto@laspolvillas.online
            </p>
          </div>

          <div class="col-12 col-sm-6 col-lg col-footer col-r">
            <h6>Ayuda</h6>

            <p>
              <router-link to="/aviso-de-privacidad">Aviso de Privacidad</router-link>
            </p>
            <!-- <p>
              <router-link to="/terminos-y-condiciones">Términos y Condiciones</router-link>
            </p> -->
            <!-- <p>
              <router-link to="">Preguntas Frecuentes</router-link>
            </p> -->
          </div>

          <div class="col-12 col-networks">
            <div class="row">
              <div class="col-lg-4 mt-3 mt-lg-0 text-center text-lg-left">
                <h6 class="mb-1 note">Empacadoras las Polvillas®</h6>
                <h6 class="mb-0 note">Todos los derechos reservados {{ currentYear }}</h6>
              </div>

              <div class="col-lg-4 mt-2 mt-sm-0 text-center">
                <div class="d-block">
                  <a class="trans-250 link" target="_blank" href="https://www.facebook.com/EmpacadoraLasPolvillasOficial"><i class="fab fa-facebook-square"></i></a>
                  <a class="trans-250 link" href=""><i class="fab fa-instagram"></i></a>
                  <a class="trans-250 link" href=""><i class="fab fa-twitter"></i></a>
                </div>

                <!-- <span class="at">@jackbrotthers</span> -->
                <!-- <span class="hashtag">#<u class="pi">Decora</u><u class="bl">tu</u><u class="pu">Mundo</u></span> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </footer>
</template>

<script>
export default {
  data(){
    return{
      form: {
        email: null
      },

      currentYear: 0,

      redes: {
        telefono: '',
        email: '',
        facebook: '#',
        instagram: '#',
        youtube: '',
        address: 'Av. Diamante 2680.<br />Col. Bosques de la Victoria<br />Guadalajara, Jal.'
      }
    }
  },

  methods:{

    makeToast(variant = null, msg, title) {
        this.$bvToast.toast(msg, {
          title: title,
          variant: variant,
          solid: true,
          toaster: 'b-toaster-top-right',
          appendToast: true
        });
    },

    onSubmit(evt) {
      evt.preventDefault();
      var r = confirm("¿Deseas regístrate en newsletter? ");
      if (r == true) {
        axios.post(tools.url("/newsletter"), this.form).then((response)=>{
          this.makeToast('default', response.data.response, 'Correo registrado');
          this.form = {
            name: null,
            email: null,
            msg: null,
            check: null
          };
        }).catch( (error)=> {
          // console.log(error.response);
          let errors = error.response.data;
          // console.log(errors);
          this.makeToast('danger', error.response.data.name, 'Error');
          this.makeToast('danger', error.response.data.email, 'Error');
          this.makeToast('danger', error.response.data.phone, 'Error');
          this.makeToast('danger', error.response.data.msg, 'Error');
        });
      }
    },

    getRedes: function(){
      axios.get(tools.url('/redes')).then((response)=>{
        this.redes = response.data;;
      }).catch((error)=>{
        console.log(error);
      })
    }

  },

  beforeMount(){
    // this.getRedes();
    this.currentYear = new Date().getFullYear();
  }
}
</script>
