<template lang="html">
  <div id="about-us-page">

    <section class="main-section">
      <i class="bg"></i>

      <div class="container oversized-container">
        <div class="row">
          <div class="offset-lg-6 col-lg-6 col-xl-5 col-contant">
            <h1 class="title">¿Quienes somos?</h1>

            <p>
              Somos una empresa 100% mexicana perfilada a la venta y producción de embutidos y carnes frias con el objetivo de entregarte la mas alta calidad en nuestros productos.
            </p>

            <h4 class="mt-4 subtitle">Misión</h4>
            <p class="mt-1">
              Brindar alimentos de calidad mediante procesos selectivos para todos nuestros productos.
            </p>

            <h4 class="mt-4 subtitle">Visión</h4>
            <p class="mt-1">
              Posicionamiento en el mercado nacional conservando y llevando acabo de manera eficiente nuestros valores.
            </p>

            <h4 class="mt-4 subtitle">Nuestros valores</h4>
            <p class="mt-1 p-lg">
              <i class="fas fa-check"></i> Calidad<br />
              <i class="fas fa-check"></i> Higiene<br />
              <i class="fas fa-check"></i> Servicio
            </p>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
