<template lang="html">
  <header id="header">
    <div class="header-content">

      <!-- Header top -->
      <div class="header-top">
        <div class="b-1">
          <h6 class="txt">Paga en línea con tu tarjeta de débito o crédito de forma segura</h6>

          <div class="imgs">
            <img class="mr-4" src="public/images/shared/i-shield.png">
            <img src="public/images/shared/i-american-express.png">
            <img src="public/images/shared/i-mastercard.png">
            <img src="public/images/shared/i-visa.png">
            <img class="ml-3" src="public/images/shared/i-paypal.svg">
            <img src="public/images/shared/i-stripe.svg">
          </div>
        </div>

        <!-- <div class="b-2">
          <h6 class="txt">o con deposito o transferencia bancaria</h6>

          <div class="imgs">
            <img src="public/images/shared/i-card.png">
          </div>
        </div> -->
      </div>
      <!--  -->

      <!-- Header menu -->
      <div class="header-menu">
        <b-navbar toggleable="lg" type="light" variant="light">
          <div class="container oversized-container">
            <b-navbar-brand to="/">
              <img src="public/images/logo.svg">
            </b-navbar-brand>

            <b-navbar-toggle target="nav-collapse">
              <i class="fal fa-bars"></i>
            </b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
              <b-navbar-nav class="mb-2 mb-lg-0">
                <b-nav-item class="simple-item" to="/">Inicio</b-nav-item>
                <!-- <b-nav-item class="simple-item" to="/modelos">Modelos</b-nav-item> -->
                <li class="nav-item simple-item">
  								<a class="nav-link" @click="isPActive = !isPActive">Catálogo <i class="fas fa-caret-down"></i></a>
  							</li>
                <b-nav-item class="simple-item" to="/nosotros">Nosotros</b-nav-item>
                <b-nav-item class="simple-item" to="/contacto">Contacto</b-nav-item>
              </b-navbar-nav>

              <b-navbar-nav class="ml-auto">
                <b-nav-item class="cart-item" to="/cart">
                  <div>
                    <i class="far fa-shopping-cart icon"></i> <span class="label">Mi carrito</span> <i class="num">0</i>
                  </div>
                </b-nav-item>

                <b-nav-item-dropdown class="user-item" right>
                  <template #button-content>
                    <span><i class="far fa-user icon"></i> <span class="label">Mi cuenta</span> </span>
                  </template>
                  <b-dropdown-item to="/login">Mi cuenta</b-dropdown-item>
                  <b-dropdown-item to="/registrarse">Registrarse</b-dropdown-item>
                </b-nav-item-dropdown>
              </b-navbar-nav>
            </b-collapse>
          </div>
        </b-navbar>
      </div>
      <!--  -->

      <!-- Header search -->
      <div class="header-search">
        <div class="container oversized-container">
          <div class="row">
            <div class="col-lg-8 mx-auto col-right">
              <div class="content">
                <b-form inline @submit="onSubmit">
                  <b-form-input
                    v-model="formSearch.keywords"
                    type="text"
                    placeholder="Buscar productos..."
                    required
                  ></b-form-input>
                  <b-button type="submit" class="t-250 btn-search">BUSCAR</b-button>

                  <!-- <router-link class="t-250 btn-asesoria" to="/contacto">ASESORÍA</router-link> -->
                </b-form>

              </div>
            </div>
          </div>
        </div>
      </div>
      <!--  -->

      <!-- Products menu -->
      <div class="t-250 products-menu" v-bind:class="{ active: isPActive }">
        <div class="menu-bg" @click="isPActive = false"></div>

        <div class="tablist" role="tablist">
          <div class="t-250 box-title" @click="isPActive = false">
            <h4>PRODUCTOS</h4>
            <br>
            <!-- <h5>Marcas</h5> -->
            <i class="fas fa-chevron-left icon"></i>
          </div>

          <b-card no-body class="mb-1" v-for="(category, pcatIndex) in categories" :key="pcatIndex">
            <b-card-header header-tag="header" role="tab">
              <div v-if="category.subcategories.length" class="btn-toggle">
                <router-link :to="'/productos/'+$root._converToURL(category.name, category.id)">{{ category.name }}</router-link>
                <span class="fake-toggle" v-b-toggle="'accordion-'+pcatIndex"></span>
              </div>
              <router-link v-else class="btn-toggle-fake" :to="'/productos/'+$root._converToURL(category.name, category.id)">{{ category.name }}</router-link>
            </b-card-header>

            <b-collapse v-if="category.subcategories.length" :id="'accordion-'+pcatIndex" :visible="category.showSubs" accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <ul class="ul-sub-options">
                  <li v-for="subcategory in category.subcategories">
                    <router-link class="t-250" :to="'/productos/'+$root._converToURL(category.name, category.id)+'/'+$root._converToURL(subcategory.name, subcategory.id)">{{ subcategory.name }}</router-link>
                  </li>
                </ul>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>
      <!--  -->

    </div>
  </header>
</template>

<script>
export default {
  data(){
    return{
      formSearch: {
        keywords: null
      },

      isPActive: false,
      categories:[
        { id: 1, name: 'Catálogo', showSubs: true, subcategories: [
            { id: 2, name: 'Chumados' },
            { id: 3, name: 'Carnes frías' },
            { id: 4, name: 'Embutídos' },
          ]
        },
      ],
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();

      this.$router.push({path: '/productos/2'});
    },
  },

  watch: {
    $route (to, from){
      // Ocultar menu cada vez que cambie la ruta
      this.isPActive = false;
    }
  },
}
</script>
