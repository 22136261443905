<template lang="html">
  <div id="home-page">

    <section class="banner-section" data-aos="fade">
      <swiper class="swiper desktop" :options="bannersOptions">
        <swiper-slide v-for="(b, bhInx) in banners.desktop" :key="'bhInx-'+bhInx">
          <div class="placed-backg box" v-bind:style="{ backgroundImage: 'url('+b.imageUrl+')' }">
            <img src="public/images/pages/home/banner.png">
          </div>
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>

      <swiper class="swiper mobile" :options="bannersOptions">
        <swiper-slide v-for="(b, bhmInx) in banners.mobile" :key="'bhmInx-'+bhmInx">
          <div class="placed-backg box" v-bind:style="{ backgroundImage: 'url('+b.imageUrl+')' }">
            <img src="public/images/pages/home/banner-m.png">
          </div>
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </section>

    <section class="container oversized-container products-section">
      <div class="d-block mb-1 mb-md-3">
        <h2 class="title-s1">Productos más populares</h2>
      </div>

      <div class="box-products">
        <swiper class="swiper" :options="productsOptions">
          <swiper-slide v-for="(p, phInx) in products" :key="'phInx-'+phInx">
            <div class="col-12 px-0 box-product-sample-s1">
              <router-link class="box-link" to="/producto/1">
                <!-- <span class="bubble">Nuevo</span> -->
                <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+p.imageUrl+')' }">
                  <img src="public/images/shared/product.png">
                </div>

                <div class="box-descr">
                  <!-- <h6 class="brand">PATEK PHILIPPE</h6> -->

                  <h6 class="name">{{ p.name }}</h6>
                  <div class="descr">
                    <p>Aquí va una descripción corta, no la descripción larga, con poco contenido, no más de 170 caracteres.</p>
                  </div>

                  <h6 class="price">${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(p.price) }}</h6>

                  <p class="mt-3">
                    <span class="btn-more">VER MÁS</span>
                  </p>
                </div>
              </router-link>
            </div>
          </swiper-slide>

          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </section>

    <section class="banner-lg-section">
      <div class="placed-backg box-banner" v-bind:style="{ backgroundImage: 'url(public/images/pages/home/banner-b-1.jpg)' }"></div>
    </section>

    <!-- <section class="container oversized-container brands-section">
      <div class="row">
        <div class="col-12 mb-1 mb-md-3 col-title">
          <h2 class="title-s1">Nuestras marcas</h2>
        </div>

        <div class="col-12 col-brands">
          <swiper class="swiper" :options="brandsOptions">
            <swiper-slide v-for="(b, brhInx) in brands" :key="'brhInx-'+brhInx">
              <div class="box-brand">
                <a :href="b.link">
                  <div class="inside">
                    <img :src="b.imageUrl">
                  </div>
                </a>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </section> -->

    <section class="container oversized-container products-section">
      <div class="d-block mb-1 mb-md-3">
        <h2 class="title-s1">Productos que recomendamos</h2>
      </div>

      <div class="box-products">
        <swiper class="swiper" :options="productsOptions">
          <swiper-slide v-for="(p, phInx) in products2" :key="'phInx-'+phInx">
            <div class="col-12 px-0 box-product-sample-s1">
              <router-link class="box-link" to="/producto/1">
                <!-- <span class="bubble">Nuevo</span> -->
                <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+p.imageUrl+')' }">
                  <img src="public/images/shared/product.png">
                </div>

                <div class="box-descr">
                  <!-- <h6 class="brand">PATEK PHILIPPE</h6> -->

                  <h6 class="name">{{ p.name }}</h6>
                  <div class="descr">
                    <p>Aquí va una descripción corta, no la descripción larga, con poco contenido, no más de 170 caracteres.</p>
                  </div>

                  <h6 class="price">${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(p.price) }}</h6>

                  <p class="mt-3">
                    <span class="btn-more">VER MÁS</span>
                  </p>
                </div>
              </router-link>
            </div>
          </swiper-slide>

          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      banners: {
        desktop: [
          { imageUrl: 'public/images/pages/home/banner-1.jpg' },
          { imageUrl: 'public/images/pages/home/banner-1.jpg' },
          { imageUrl: 'public/images/pages/home/banner-1.jpg' },
        ],

        mobile: [
          { imageUrl: 'public/images/pages/home/banner-1-m.jpg' },
          { imageUrl: 'public/images/pages/home/banner-1-m.jpg' },
          { imageUrl: 'public/images/pages/home/banner-1-m.jpg' },
        ]
      },

      products: [
        { imageUrl: 'public/images/pages/products/product-1.jpg', price: '120', name: 'Tocino Chumado' },
        { imageUrl: 'public/images/pages/products/product-2.jpg', price: '150', name: 'Chuleta chumada' },
        { imageUrl: 'public/images/pages/products/product-3.jpg', price: '170', name: 'Jamon York' },
        { imageUrl: 'public/images/pages/products/product-4.jpg', price: '140', name: 'Jamon Virgínia' },
        { imageUrl: 'public/images/pages/products/product-5.jpg', price: '100', name: 'Jamon Dorado' },
      ],

      products2: [
        { imageUrl: 'public/images/pages/products/product-6.jpg', price: '120', name: 'Pierna española' },
        { imageUrl: 'public/images/pages/products/product-7.jpg', price: '125', name: 'Jamon cocido' },
        { imageUrl: 'public/images/pages/products/product-11.jpg', price: '210', name: 'Chorizo argentino' },
        { imageUrl: 'public/images/pages/products/product-8.jpg', price: '150', name: 'Jamon horneado' },
        { imageUrl: 'public/images/pages/products/product-14.jpg', price: '170', name: 'Chorizo especial' },
      ],

      brands: [],

      // == Carousel options ==
      bannersOptions: {
        effect: 'fade',
        loop: true,
        speed: 700,

        autoplay: {
          delay: 4000,
          disableOnInteraction: false
        },

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },

      brandsOptions: {
        loop: true,
        slidesPerView: 4,
        spaceBetween: 20,

        autoplay: {
          delay: 4500,
          disableOnInteraction: false
        },

        breakpoints: {
          1300: {
            slidesPerView: 4,
          },
          992: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 2,
          },
          0: {
            slidesPerView: 1,
          },
        }
      },

      productsOptions: {
        slidesPerView: 3,
        spaceBetween: 30,

        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },

        breakpoints: {
          1100: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 2,
          },
          1: {
            slidesPerView: 1,
          },
        }
      }
      // == ==
    }
  }
}
</script>
