<template lang="html">
  <b-form id="products-results-page" @submit="onSubmit">

    <section class="products-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-12 box-breadcrumb-s1">
            <span>Home</span>
            <router-link to="">Productos</router-link>
          </div>
        </div>

        <div class="row">
          <!-- Mobile filters -->
          <div class="col-12 col-filters-mobile">
            <div class="bg-filter-mv" v-if="showFilters == true" @click="showFilters = false"></div>
            <a class="btn-filters" @click="showFilters = !showFilters">
              <i class="fas fa-filter"></i><span>Ver filtros</span>
            </a>
          </div>
          <!--  -->

          <!-- Col filters -->
          <div class="col-lg col-filters" v-bind:class="{ 'show-filters': showFilters == true }">
            <h4 class="d-lg-none _title">
              <span><strong>Filtrar</strong></span>
              <a class="btn-hide" @click="showFilters = false"><i class="fas fa-chevron-left"></i></a>
            </h4>

            <div class="filters-container">
              <div class="box-filters">
                <h6 class="group-f-title">Catálogo</h6>

                <b-form-group class="box-filters-group" v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group
                    v-model="formSearch.category"
                    :aria-describedby="ariaDescribedby"
                    name="fil-0">
                    <b-form-checkbox
                      :value="m.id"
                      v-for="(m, mInx) in marcas"
                      :key="'mInx-'+mInx">
                      {{ m.name }}
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                </b-form-group>
              </div>

            </div>
          </div>
          <!--  -->

          <!-- Col products -->
          <div class="col-lg col-results">
            <div class="row">
              <div class="col-12 col-title">
                <h4 class="title-s1">Productos</h4>
              </div>

              <div class="col-sm-6 col-lg-6 col-xl-4 box-product-sample-s1" v-for="(p, pInx) in products" :key="'pInx-'+pInx">
                <router-link class="box-link" to="/producto/1">
                  <!-- <span class="bubble">Nuevo</span> -->
                  <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+p.imageUrl+')' }">
                    <img src="public/images/shared/product.png">
                  </div>

                  <div class="box-descr">
                    <!-- <h6 class="brand">PATEK PHILIPPE</h6> -->

                    <h6 class="name">{{ p.name }}</h6>
                    <div class="descr">
                      <p>Aquí va una descripción corta, no la descripción larga, con poco contenido, no más de 170 caracteres.</p>
                    </div>

                    <h6 class="price">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(p.price) }}</h6>

                    <p class="mt-3">
                      <span class="btn-more">VER MÁS</span>
                    </p>
                  </div>
                </router-link>
              </div>
            </div>

            <div class="row mt-4" v-if="pagination.total_products > 6">
              <div class="col-12 text-center">
                <div class="d-inline-block mx-0 col-pagination-sample-1">
                  <b-pagination-nav :link-gen="linkGen" :number-of-pages="pagination.total" use-router></b-pagination-nav>
                </div>
              </div>
            </div>
          </div>
          <!--  -->

        </div>
      </div>
    </section>

  </b-form>
</template>

<script>
export default {
  data() {
    return {
      showFilters: false,

      marcas: [
        { id: 1, name: 'Chumados' },
        { id: 2, name: 'Carnes frías' },
        { id: 3, name: 'Embutídos' },
      ],

      formSearch: {
        keywords: null,
        order: 1,
        category: [],
        shape: [],
        price: null,
        discount: null,
      },

      products: [
        { imageUrl: 'public/images/pages/products/product-1.jpg', price: '120', name: 'Tocino Chumado' },
        { imageUrl: 'public/images/pages/products/product-2.jpg', price: '150', name: 'Chuleta chumada' },
        { imageUrl: 'public/images/pages/products/product-3.jpg', price: '170', name: 'Jamon York' },
        { imageUrl: 'public/images/pages/products/product-4.jpg', price: '140', name: 'Jamon Virgínia' },
        { imageUrl: 'public/images/pages/products/product-5.jpg', price: '100', name: 'Jamon Dorado' },
        { imageUrl: 'public/images/pages/products/product-6.jpg', price: '120', name: 'Pierna española' },
        { imageUrl: 'public/images/pages/products/product-7.jpg', price: '125', name: 'Jamon cocido' },
        { imageUrl: 'public/images/pages/products/product-8.jpg', price: '105', name: 'Jamon horneado' },
        { imageUrl: 'public/images/pages/products/product-10.jpg', price: '105', name: 'Jamon Aris' },
        { imageUrl: 'public/images/pages/products/product-11.jpg', price: '210', name: 'Chorizo argentino' },
        { imageUrl: 'public/images/pages/products/product-12.jpg', price: '150', name: 'Chistorra' },
        { imageUrl: 'public/images/pages/products/product-14.jpg', price: '170', name: 'Chorizo especial' },
      ],

      pagination:{
        currentpage: 1,
        total: 1,
        total_products: 16,
        number: 10
      },
    }
  },

  methods: {
    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`
    },

    onSubmit(event) {
      event.preventDefault();
    },
  },

  mounted() {
  }
}
</script>
